
import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

export default function ProfileModal({ closeModal }) {

    const navigate = useNavigate();
    const { logout, getUsername, getEmail, isBooster, isAdmin } = useAuth();

    useEffect(() => {
        document.getElementById('popup-root').classList.remove('popup-profile-modal')
        return () => {
            document.getElementById('popup-root').classList.add('popup-profile-modal')
            closeModal();
        }
    }, [])
    

    return (
        <div className="profile-modal">
            <div className="profile-modal-content">
                <div className="profile-modal-header">
                    <div className="profile-picture-username">
                        <img src={require('../../assets/Logos/MascotFace.png')} id="profile-pic" />
                        <h2>{getUsername()}</h2>
                    </div>
                    <p>{getEmail()}</p>
                </div>
                <div className="profile-modal-body">
                    <div className="profile-modal-body-buttons">
                        <a href={`${process.env.REACT_APP_CLIENT_URL}/my-orders/boosting/active`} className="profile-modal-body-button link-hover">
                            My Orders
                        </a>
                        {isBooster && <a href={`${process.env.REACT_APP_CLIENT_URL}/boost-panel/boosting/pending`} className="profile-modal-body-button link-hover">
                         Booster Dashboard
                        </a>}
                        {isAdmin && <a href={`${process.env.REACT_APP_CLIENT_URL}/admin-panel/boosting/pending`} className="profile-modal-body-button link-hover">
                            Admin Dashboard
                        </a>}
                        <a href={`${process.env.REACT_APP_CLIENT_URL}/my-orders/messages`} className="profile-modal-body-button link-hover">
                            Messages
                        </a>
                        <button className="profile-modal-body-button  link-hover" onClick={() => logout()}>Log Out</button>
                    </div>
                </div>
            </div>
        </div>

    );
}