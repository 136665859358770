
import React, { useEffect, useState } from 'react'

import { SelectorButton, SelectorContainer } from '../../../components/Selector.js';
import { EMBLEMS } from '../../../constants/colors.js';
import DropDown from '../../../components/DropDown.js';

import { LP as LP_CONSTANT } from '../../../constants/database_values.js';

export default function CurrentSelector({update, updateRank, updateDivision, LP_DISABLED}) {

    const [rank, setRank] = useState(updateRank || 'Silver');
    const [LP, setLP] = useState(LP_CONSTANT.LOW.VALUE);
    const [division, setDivision] = useState(updateDivision || 1);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    useEffect(() => {
        if(rank === 'Diamond' && division === 1) {
            setDivision(2);
            update({rank, division: 2, LP});
            return;
        }
        update({rank, division, LP});
    }, [LP, division, rank]);

    useEffect(() => {
        if(updateRank === undefined || updateRank === null) return;
        if(updateRank === 'Diamond' && division === 1) {
            setDivision(2);
        }
        if(updateRank === 'Master') {
            setRank('Diamond');
            setDivision(2);
            return;
        }
        setRank(updateRank);
    }, [updateRank]);

    useEffect(() => {
        if(updateDivision === undefined || updateDivision === null) return;
        if(updateRank === 'Diamond' && updateDivision === 1) {
            setDivision(2);
            return;
        }
        if(updateDivision > 4) {
            setDivision(4);
            return;
        } 
        if(updateDivision < 1) {
            setDivision(1);
            return;
        }
        setDivision(updateDivision);
    }, [updateDivision]);

    try {
        return(
            <div className={'rank-selector current'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
                <div className='component-title'>
                    <h2>Current Rank</h2>
                </div>
                <img id='emblem' src={require(`../../../assets/Emblems/${rank}_Emblem.png`)}/>
                <DropDown elements={ranks} onChange={setRank} defaultValue={rank}/>
                <SelectorContainer setCurrentValue={setDivision} currentValue={division}>
                    <SelectorButton value={4}>IV</SelectorButton>
                    <SelectorButton value={3}>III</SelectorButton>
                    <SelectorButton value={2}>II</SelectorButton>
                    <SelectorButton value={1} disabled={rank === 'Diamond'}>I</SelectorButton>
                </SelectorContainer>
                {!LP_DISABLED &&
                <SelectorContainer setCurrentValue={setLP} currentValue={LP}>
                    <SelectorButton value={LP_CONSTANT.LOW.VALUE}>{LP_CONSTANT.LOW.BUTTON_DISPLAY}</SelectorButton>
                    <SelectorButton value={LP_CONSTANT.MEDIUM.VALUE}>{LP_CONSTANT.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                    <SelectorButton value={LP_CONSTANT.HIGH.VALUE}>{LP_CONSTANT.HIGH.BUTTON_DISPLAY}</SelectorButton>
                </SelectorContainer>}
                
                {!LP_DISABLED && 
                <p style={{marginBottom:'1rem'}}>Current LP</p>
                }
                {LP_DISABLED &&
                <div style={{marginBottom:'1rem'}}/>
                }
            </div>
        );
    } catch {
        return null;
    }
}