
import React from 'react';

import './CSS/PrivacyPolicy.css';

export default function PrivacyPolicy() {

    const [language, setLanguage] = React.useState('en');
    
    if(language === 'es') {
        return(
            <div className='terms-of-service'>
                <h1>AVISO DE PRIVACIDAD</h1>
                <p>Última actualización: 24 de julio del 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                        PROTECCIÓN DE DATOS PERSONALES
                    </h2>
                    <p>
                    Al crear una Cuenta SMURF GG, Usted accede a la recopilación y uso de su información conforme a este aviso de privacidad, al igual que bajo lo establecido por los términos y condiciones publicados en esta Página. Los datos personales que SMURF GG posea o recabe serán los datos personales que Usted nos proporcione voluntariamente del llenado de diversas formas en la Página de SMURF GG.                    </p>
                    <br />
                    <p>
                    Datos personales: Para utilizar y disfrutar de nuestros Servicios, le solicitaremos nos proporcione información que será utilizada únicamente para identificarlo personalmente en nuestra base de datos de Usuarios. La información solicitada incluirá, sin limitarse a:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Dirección IP (IP Address)
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Nombre y Apellido del Usuario
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Username de LOL y/o del Videojuego que se trate
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Summoner Name
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Correo Electrónico
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Región
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Compras anteriores en SMURF GG
                            </p>
                        </li>
                        <li>
                            <p>
                                viii) Contraseña de LOL (dependiendo del Servicio adquirido)                            
                            </p>
                        </li>
                        <li>
                            <p>
                                ix) Fecha de Nacimiento (opcional)
                            </p>
                        </li>
                    </ul>
                    <p>
                    La información recolectada será utilizada por SMURF GG para lo siguiente:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Proporcionar exitosamente al Usuario los Servicios de Coaching y/o Boosting.
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Notificar información relevante sobre actualizaciones en las políticas de servicio y/o términos y condiciones.
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Recopilar estadísticas y encuestas para mejorar el servicio.
                            </p>
                        </li>
                    </ul>
                    <p>
                    La Página utiliza cookies para identificar la autenticacion del Usuario, con el único objetivo de guardar la información del Usuario, por lo que se deberá aceptar el uso de cookies para acceder a la Página y a los Servicios ofrecidos en ella.
                    </p>
                    <br />
                    <p>
                    SMURF GG jamás divulgará la información y datos personales de los Usuarios a terceros sin contar con consentimiento previo del Usuario, salvo que la divulgación de los datos personales sea requerida para que el Entrenador pueda proporcionar el servicio adquirido por el Usuario.
                    </p>
                    <br />
                    <p>
                    Los Usuarios podrán solicitar los datos personales recaudados por SMURF GG en todo momento. 
                    </p>
                    <br />
                    <p>
                    Los Usuarios tendrán en todo momento la posibilidad de ejercer sus derechos de Acceso, Ratificación, Cancelación y Oposición (ARCO), según se definen en la Ley General de Protección de Datos Personales en Posesión de Particulares y el Artículo 16 de la Constitución Política de los Estados Unidos Mexicanos. Asimismo, Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. No obstante, es importante que Usted considere que no en todos los casos se podrá atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación de seguir prestando los Servicios requiramos seguir tratando sus datos personales. Asimismo, Usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que SMURF GG no le podrá seguir prestando los Servicios que nos solicitó, o la conclusión de su relación con SMURF GG.
                    </p>
                    <br />
                    <p>
                    Para el ejercicio de sus derechos ARCO, deberá enviar un correo electrónico a  support@smurf.gg con su solicitud, copia de su identificación, la precisión de los derechos que desea ejercer y una descripción clara de los datos personales respecto de los cuales desea ejercer alguno de sus derechos ARCO. El departamento de soporte de SMURF GG admitirá su solicitud en un término de 20 (veinte) días y deberá resolver sobre dicha solicitud en un término de 15 (quince) días posteriores a la admisión de la solicitud. 
                    </p>
                    <br />
                    <p>
                    En caso de que la revocación del consentimiento para el uso de datos personales implique la imposibilidad de continuar prestando los Servicios, SMURF GG podrá suspender su cuenta y le notificará sobre dicha imposibilidad. 
                    </p>
                    <br />
                    <p> 
                    Los cambios al Aviso de Privacidad serán informados a los Usuarios previo a su publicación en la Página, para que los Usuarios puedan expresar su consentimiento.
                    </p>
                    <br />
                    <p>
                    SMURF GG y sus entidades afiliadas estarán obligados en todo momento a cumplir con las leyes mexicanas en materia de protección de privacidad, especialmente la Ley de Protección de Datos Personales en Posesión de Particulares, y las demás relativas en la materia.
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Idioma</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>Inglés</option>
                        <option value='es'>Español</option>
                    </select>
                </div>
            </div>
        );
    } else if(language === 'en') {
        return(
            <div className='terms-of-service'>
                <h1>PRIVACY NOTICE</h1>
                <p>Last updated: July 24, 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                    PROTECTION OF PERSONAL INFORMATION
                    </h2>
                    <p>
                    By creating a SMURF GG Account, Users agree to the collection and use of their information in accordance with this Privacy Notice, as well as with the Terms and Conditions found on this Website. All personal data collected and compiled by SMURF GG will consist of information provided voluntarily by Users through the filling of diverse forms and sections on the SMURF GG Website.                    
                    </p>
                    <br />
                    <p>
                    Personal Data: In order to access and enjoy our Services, we will request information that will be exclusively used to identify the Users from within our User Database. The information requested will include, but is not limited to:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) IP Address
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Name and Last Name
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Username for LOL and/or any other Videogame
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Summoner Name
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Email Address
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Region
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Previous purchases on SMURF GG
                            </p>
                        </li>
                        <li>
                            <p>
                                viii) LOL Password (depending on the Service purchased)                    
                            </p>
                        </li>
                        <li>
                            <p>
                                ix) Date of Birth (optional)
                            </p>
                        </li>
                    </ul>
                    <p>
                    The information collected will be used by SMURF GG for the following:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Successfully provide the Coaching and/or Boosting Services purchased by the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Notify any relevant information regarding updates in our Terms and Conditions.
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Compile statistical information and surveys to improve our service.
                            </p>
                        </li>
                    </ul>
                    <p>
                    This Website uses cookies to identify the Users’ website authentication information, with the sole purpose of collecting and saving User’s information. Therefore, Users must accept the use of cookies in order to access the Website and the Services offered therein.                    
                    </p>
                    <br />
                    <p>
                    SMURF GG will never disclose or share the Users’ personal data and information to any unauthorized third party without the User’s prior consent, unless the disclosure of said personal data is required by the Coach to provide the Services purchased by the Users.
                    </p>
                    <br />
                    <p>
                    Users may request a detailed account of the personal data collected by SMURF GG at any time.
                    </p>
                    <br />
                    <p>
                    Users will at all times have the possibility to exercise their rights to Access, Ratification, Cancellation, and Opposition of personal information (ARCO rights), as defined by the General Law for the Protection of Personal Data in Possession of Individuals (Ley General de Protección de Datos Personales en Posesión de Particulares) and Article 16 of the Constitution of the United Mexican States. Likewise, Users may revoke the consent given to us for the processing of their personal data. Notwithstanding, it is important for Users to consider that in some cases their request may not be able to be processed and/or resolved immediately, since it is possible that by some existing obligation to provide the Services, we must continue to process said personal data. Additionally, Users must consider that, for certain purposes, the revocation of consent will imply the impossibility of SMURF GG to continue providing the Services or the finalization of the User’s relationship with SMURF GG.
                    </p>
                    <br />
                    <p>
                    To exercise any of the ARCO rights, Users must send an email to support@smurf.gg containing their request and including a copy of official ID, specification of the rights User wishes to exercise, and a clear account of the personal data whereof User wishes to exercise any of the ARCO rights. SMURF GG’s support department will admit User’s request within a period of 20 (twenty) days and resolve within a period of 15 (fifteen) days starting on the date of admission of the request.
                    </p>
                    <br />
                    <p> 
                    In case the revocation of User’s consent for the use of personal data results in impossibility to continue providing the Services, SMURF GG may then suspend User’s account. In any case, SMURF GG will notify User of the impossibility to provide said Services.
                    </p>
                    <br />
                    <p>
                    Any modification to this Privacy Notice will be informed to the Users prior to being published on the Website, in order for Users to read and accept the new version.
                    </p>
                    <br />
                    <p>
                    SMURF GG and its affiliates shall be bound at all times to comply with Mexican Laws regarding protection of personal data, particularly the General Law for the Protection of Personal Data in Possession of Individuals, and any other applicable regulation.
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Language</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>English</option>
                        <option value='es'>Spanish</option>
                    </select>
                </div>
            </div>
        );
    }
}