
import React from "react"
import { Outlet, Navigate, useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios"

export default function BoosterRoute({ component: Component, ...rest }) {

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const { isBooster, acceptedCut, cut, getAccessToken } = useAuth();

  if(isBooster && acceptedCut) {
    return (<Outlet/>)
  } else if(isBooster && !acceptedCut) {
    if(process.env.REACT_APP_TESTING_MODE === 'true')
      return
    if(!window.location.href.includes('terms-of-service')) {
      MySwal.fire({
        title: 'Terms and conditions update!',
        text: `Current commission ${cut}%. Please accept the new terms and conditions to continue being a booster. Please review your terms and conditions: https://smurf.gg/terms-of-service/boosters`,
        icon: 'warning',
        confirmButtonText: 'Accept',
        showCancelButton: true,
        cancelButtonText: 'Decline'
      }).then((result) => {
        if(result.isConfirmed) {
          axios.post(process.env.REACT_APP_API_URL + '/boost/accept-cut', { accepted: true }, {
            headers: { 
                Authorization: getAccessToken() 
            },
          }).then(() => {
            window.location.reload();
          }).catch(() => {
            window.location.reload();
          });
        } else {
          navigate('/');
        }
      });
    }
    return (<Outlet/>)
  } else {
    return <Navigate to="/access-denied"/>
  }
}