
import React, { useState, useEffect } from 'react'

import DesiredSelector from './DivisionBoost/DesiredSelector.js';
import CurrentSelector from './DivisionBoost/CurrentSelector.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS, LP_GAINS, LP as LP_CONSTANT } from '../../constants/database_values.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../components/GeneralContainers.js';

import { Helmet } from 'react-helmet';

export default function DivisionBoost({setData}) { 

    const helmetTags = require('../../constants/helmet-tags.json');

    const servers = ['NA', 'LAN'];
    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    const previousData = JSON.parse(localStorage.getItem('division-boosting')) || {};

    const [rank, setRank] = useState((previousData.rank && ranks.includes(previousData.rank)) ? previousData.rank : 'Silver'); 
    const [division, setDivision] = useState(previousData.division || 4);
    const [LP, setLP] = useState(0);
    const [queueType, setQueueType] = useState(previousData.queueType || 'Solo/Duo');
    const [server, setServer] = useState((previousData.server && servers.includes(previousData.server)) ? previousData.server : 'NA');
    const [desiredRank, setDesiredRank] = useState(null);
    const [desiredDivision, setDesiredDivision] = useState(null);
    const [LPGains, setLPGains] = useState(previousData.LPGains || LP_GAINS.MEDIUM.VALUE);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState(previousData.addons || []);
    const [champions, setChampions] = useState(previousData.champions || []);
    const [roles, setRoles] = useState(previousData.roles || []);
    const [currency, setCurrency] = React.useState('USD');

    useEffect(() => {
        const fetchCurrency = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                if(response.data.currency === 'MXN'){
                    setCurrency('MXN');
                } else {
                    setCurrency('USD');
                }
            } catch (error) {
                setCurrency('USD');
                console.error('Error fetching currency:', error);
            }
        };
        fetchCurrency();
    }, []);

    useEffect(() => {
        setData({
            rank,
            division,
            LP,
            queueType,
            server,
            desiredRank,
            desiredDivision,
            LPGains,
            addons,
            champions,
            roles,
            price
        });
        localStorage.setItem('division-boosting', JSON.stringify({
            rank,
            division,
            LP,
            queueType,
            server,
            desiredRank,
            desiredDivision,
            LPGains,
            addons,
            champions,
            roles
        }));
    }, [rank, division, LP, queueType, server, desiredRank, desiredDivision, LPGains, addons, champions, roles, price]);

    function updateCurrent({rank, division}) {
        setRank(rank);
        setDivision(division);
    }

    function updateDesired({rank, division}) {
        setDesiredRank(rank);
        setDesiredDivision(division);
    }

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    useEffect(() => {
        if(rank === null || division === null || LP === null || desiredRank === null || desiredDivision === null || LPGains === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-division-boost', { 
            rank,
            division,
            LP,
            desiredRank,
            desiredDivision,
            LPGains,
            addons,
            currency
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [rank, division, LP, desiredRank, desiredDivision, LPGains, addons, currency]);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.division_boosting.title}</title>
                <meta name="description" content={helmetTags.division_boosting.description}/>
                <link rel="canonical" href={helmetTags.division_boosting.link}/>
            </Helmet>
            <div className='division-boosting-ranks'>
                <CurrentSelector key={'Current'} update={updateCurrent} updateRank={(previousData.rank && ranks.includes(previousData.rank)) ? previousData.rank : 'Silver'} updateDivision={previousData.division || 4} LP_DISABLED={true}/>
                <DesiredSelector key={'Desired'} update={updateDesired} updateRank={(previousData.desiredRank && ranks.includes(previousData.desiredRank)) ? previousData.desiredRank : 'Silver'} updateDivision={previousData.desiredDivision || 4} minRank={rank} maxDivision={division} currentLPGains={LPGains} LP_DISABLED={true}/>
            </div>
            <div className='division-boosting-selectors'>
                <div className='division-boosting-selector'>
                    <div className='component-title'>
                        <h2>Current LP</h2>
                    </div>
                    <div className='component-wrapper'>
                        <SelectorContainer setCurrentValue={setLP} currentValue={LP}>
                            <SelectorButton value={LP_CONSTANT.LOW.VALUE}>{LP_CONSTANT.LOW.BUTTON_DISPLAY}</SelectorButton>
                            <SelectorButton value={LP_CONSTANT.MEDIUM.VALUE}>{LP_CONSTANT.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                            <SelectorButton value={LP_CONSTANT.HIGH.VALUE}>{LP_CONSTANT.HIGH.BUTTON_DISPLAY}</SelectorButton>
                        </SelectorContainer>
                    </div>
                </div>
                <div className='division-boosting-selector'>
                    <div className='component-title'>
                        <h2>Current LP Gains</h2>
                    </div>
                    <div className='component-wrapper'>
                        <SelectorContainer setCurrentValue={setLPGains} currentValue={LPGains}>
                            <SelectorButton value={LP_GAINS.LOW.VALUE}>{LP_GAINS.LOW.BUTTON_DISPLAY}</SelectorButton>
                            <SelectorButton value={LP_GAINS.MEDIUM.VALUE}>{LP_GAINS.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                            <SelectorButton value={LP_GAINS.HIGH.VALUE}>{LP_GAINS.HIGH.BUTTON_DISPLAY}</SelectorButton>
                        </SelectorContainer>
                    </div>
                </div>
            </div>
            <div className='division-boosting-selectors'>
                <div className='division-boosting-selector'>
                    <div className='component-title'>
                        <h2>Queue Type</h2>
                    </div>
                    <div className='component-wrapper'>
                        <SelectorContainer setCurrentValue={setQueueType} currentValue={queueType}>
                            <SelectorButton value={'Solo/Duo'}>Solo/Duo</SelectorButton>
                            <SelectorButton value={'Flex'}>Flex</SelectorButton>
                        </SelectorContainer>
                    </div>
                </div>
                <div className='division-boosting-selector'>
                    <div className='component-title'>
                        <h2>Region</h2>
                    </div>
                    <div className='component-wrapper'>
                        <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                            {servers.map((server) => {
                                return(
                                    <SelectorButton key={server} value={server}>{server}</SelectorButton>
                                );
                            })}
                        </SelectorContainer>
                    </div>
                </div>
            </div>
            <div className='division-boosting-addons'>
                <div className='component-title'>
                    <h2>Addons</h2>
                </div>
                <div className='component-wrapper'>
                    <div className='ordering-status-buttons'>
                        {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                            return(
                                <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                            )}
                        )}
                    </div>
                </div>
            </div>
            {addons.includes('Specific Roles')&& <div className='division-boosting-addons' style={{alignSelf:'stretch'}}>
                <RoleSelector selectedRoles={setRoles} previousRoles={previousData.roles} noScroll={previousData.roles !== null && previousData.roles?.length > 0}/>
            </div>}
            {addons.includes('Specific Champions')&& <div className='division-boosting-addons'>
               <ChampionSelector selectedChampions={setChampions} previousChampions={previousData.champions} noScroll={previousData.champions !== null && previousData.champions?.length > 0}/>
            </div>}
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}