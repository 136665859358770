
import { useAuth } from '../contexts/AuthContext.js';
import React, { useEffect, useRef } from 'react';
import { BOOST_TYPE, ADDONS, LP_GAINS, LP } from '../constants/database_values.js';
import axios from 'axios';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function Checkout({ panel, setShowLogin, data, admin }) {

    const { getAccessToken } = useAuth();
    const checkoutRef = useRef(null);

    const MySwal = withReactContent(Swal);

    const [price, setPrice] = React.useState(0);
    const [content, setContent] = React.useState([]);
    const [coupon, setCoupon] = React.useState('');
    const [shakeCoupon, setShakeCoupon] = React.useState(false);
    const [discount, setDiscount] = React.useState(false);
    const [discountPercent, setDiscountPercent] = React.useState(0);
    const [checkoutHeight, setCheckoutHeight] = React.useState(0);
    const [currency, setCurrency] = React.useState('');

    useEffect(() => {
        const fetchCurrency = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                if(response.data.currency === 'MXN'){
                    setCurrency('MXN');
                } else {
                    setCurrency('USD');
                }
            } catch (error) {
                setCurrency('USD');
                console.error('Error fetching currency:', error);
            }
        };
        fetchCurrency();
    }, []);

    useEffect(() => {
        const handleResize = (entries) => {
            for (let entry of entries) {
                if (entry.target === checkoutRef.current) {
                    if(checkoutHeight !== entry.contentRect.height){
                        setCheckoutHeight(entry.contentRect.height);
                    }
                }
            }
        };
        const observer = new ResizeObserver(handleResize);
        if (checkoutRef.current) {
          observer.observe(checkoutRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, []);


    function getAddonPrice(addon, prices) {
        switch(addon) {
            case 'Duo':
                return prices.duo;
            case 'Priority':
                return prices.priority;
            case 'Streaming':
                return prices.streaming;
            case 'Solo Only':
                return prices.soloOnly;
            case 'Specific Champions':
                return prices.specificChampions;
            case 'Specific Roles':
                return prices.specificRoles;
            case 'Bonus Win':
                return prices.bonusWin;
            default:
                return 0;
        }
    }

    useEffect(() => {
        if(data === null || !data || data === undefined || Object.keys(data).length === 0){
            return;
        }
        switch(panel) {
            case 'Coaching':
                if( data.orderLanguage === undefined ||
                    data.coachRank === undefined || 
                    data.usersRank === undefined ||
                    data.copilotHours === undefined ||
                    data.vodHours === undefined ||
                    data.battleHours === undefined ||
                    data.server === undefined ||
                    data.price === undefined) {
                    return;
                }
                setPrice(data.price.totalPrice);
                setContent([
                    <tbody key='Coaching'>
                        <tr>
                            <td className='left strong'>Coaching</td>
                            <td className='right'>${data.price.price??'...'} {currency}</td>
                        </tr>
                        <tr>
                            <td className='left'>Coach Rank [{data.coachRank}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Language [{data.orderLanguage}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Server [{data.server}]</td>
                        </tr>
                        {data.copilotHours > 0 &&
                        <tr>
                            <td className='left'>Copilot Hours [{data.copilotHours}]</td>
                        </tr>}
                        {data.vodHours > 0 &&
                        <tr>
                            <td className='left'>VOD Review Hours [{data.vodHours}]</td>
                        </tr>}
                        {data.battleHours > 0 &&
                        <tr>
                            <td className='left'>1v1 Hours [{data.battleHours}]</td>
                        </tr>}
                    </tbody>
                ]);
                break;
            case BOOST_TYPE.DIVISION_BOOSTING:
                if( data.rank === undefined || 
                    data.division === undefined || 
                    data.LP === undefined || 
                    data.queueType === undefined ||
                    data.server === undefined ||
                    data.desiredRank === undefined || 
                    data.desiredDivision === undefined || 
                    data.LPGains === undefined ||
                    data.price === undefined) {
                    return;
                }
                setPrice(data.price.totalPrice);
                setContent([
                    <tbody key={BOOST_TYPE.DIVISION_BOOSTING}>
                        <tr>
                            <td className='left strong'>Division Boosting</td>
                            <td className='right'>${data.price.price??'...'} {currency}</td>
                        </tr>
                        <tr>
                            <td className='left'>Current Rank [{data.rank} {data.division}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Current LP [{LP.DISPLAY(data.LP)}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Desired Rank [{data.desiredRank} {data.desiredDivision}]</td>
                        </tr>
                        <tr>
                            <td className='left'>LP Gains [{LP_GAINS.DISPLAY(data.LPGains)}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Queue Type [{data.queueType}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Server [{data.server}]</td>
                        </tr>
                        {data.addons && data.addons.map((addon) => {
                            return(
                                <tr key={addon}>
                                    <td className='left strong'>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'left', textAlign:'right', gap:'0.25rem'}}>
                                            {addon}
                                            <p style={{color:'white', fontSize:'0.75rem'}}>
                                            {ADDONS.GET_LABEL(addon)}
                                            </p>
                                        </div></td>
                                    {getAddonPrice(addon, data.price) === 0 ? 
                                    <td className='right'>{ADDONS.GET_LABEL(addon)}</td> : 
                                    <td className='right'>
                                        ${getAddonPrice(addon, data.price)} {currency}
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                ]);
                break;
            case BOOST_TYPE.PLACEMENT_GAMES:
                if( data.rank === undefined || 
                    data.numGames === undefined || 
                    data.queueType === undefined ||
                    data.server === undefined ||
                    data.price === undefined) {
                    return;
                }
                setPrice(data.price.totalPrice);
                setContent([
                    <tbody key={BOOST_TYPE.PLACEMENT_GAMES}>
                        <tr>
                            <td className='left strong'>Placement Games</td>
                            <td className='right'>${data.price.price??'...'} {currency}</td>
                        </tr>
                        <tr>
                            <td className='left'>Previous Rank [{data.rank}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Number of Games [{data.numGames}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Queue Type [{data.queueType}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Server [{data.server}]</td>
                        </tr>
                        {data.addons && data.addons.map((addon) => {
                            return(
                                <tr key={addon}>
                                    <td className='left strong'>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'left', textAlign:'right', gap:'0.25rem'}}>
                                            {addon}
                                            <p style={{color:'white', fontSize:'0.75rem'}}>
                                            {ADDONS.GET_LABEL(addon)}
                                            </p>
                                        </div></td>
                                    {getAddonPrice(addon, data.price) === 0 ? 
                                    <td className='right'>{ADDONS.GET_LABEL(addon)}</td> : 
                                    <td className='right'>
                                        ${getAddonPrice(addon, data.price)??'...'} {currency}
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                ]);
                break;
            case BOOST_TYPE.NET_WINS:
                if( data.rank === undefined || 
                    data.division === undefined ||
                    data.LPGains === undefined || 
                    data.queueType === undefined ||
                    data.numNetWins === undefined ||
                    data.server === undefined ||
                    data.price === undefined) {
                    return;
                }
                setPrice(data.price.totalPrice);
                setContent([
                    <tbody key={BOOST_TYPE.NET_WINS}>
                        <tr>
                            <td className='left strong'>Net Wins</td>
                            <td className='right'>${data.price.price??'...'} {currency}</td>
                        </tr>
                        <tr>
                            <td className='left'>Current Rank [{data.rank} {data.division}]</td>
                        </tr>
                        <tr>
                            <td className='left'>LP Gains [{LP_GAINS.DISPLAY(data.LPGains)}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Number of Net Wins [{data.numNetWins}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Queue Type [{data.queueType}]</td>
                        </tr>
                        <tr>
                            <td className='left'>Server [{data.server}]</td>
                        </tr>
                        {data.addons && data.addons.map((addon) => {
                            return(
                                <tr key={addon}>
                                    <td className='left strong'>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'left', textAlign:'right', gap:'0.25rem'}}>
                                            {addon}
                                            <p style={{color:'white', fontSize:'0.75rem'}}>
                                            {ADDONS.GET_LABEL(addon)}
                                            </p>
                                        </div></td>
                                    {getAddonPrice(addon, data.price) === 0 ? 
                                    <td className='right'>{ADDONS.GET_LABEL(addon)}</td> : 
                                    <td className='right'>
                                        ${getAddonPrice(addon, data.price)??'...'} {currency}
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                ]);
                break;
            case BOOST_TYPE.NORMAL_GAMES:
                if( data.orderType === undefined ||
                    data.numGames === undefined ||
                    data.server === undefined ||
                    data.price === undefined) {
                    return;
                }
                setPrice(data.price.totalPrice);
                setContent([
                    <tbody key={BOOST_TYPE.NORMAL_GAMES}>
                        <tr>
                            <td className='left strong'>Normal Games</td>
                            <td className='right'>${data.price.price??'...'} {currency}</td>
                        </tr>
                        <tr>
                            <td className='left'>[{data.numGames}] {data.orderType}</td>
                        </tr>
                        <tr>
                            <td className='left'>Queue Type [Draft]</td>
                        </tr>
                        <tr>
                            <td className='left'>Server [{data.server}]</td>
                        </tr>
                        {data.addons && data.addons.map((addon) => {
                            return(
                                <tr key={addon}>
                                    <td className='left strong'>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'left', textAlign:'right', gap:'0.25rem'}}>
                                            {addon}
                                            <p style={{color:'white', fontSize:'0.75rem'}}>
                                            {ADDONS.GET_LABEL(addon)}
                                            </p>
                                        </div></td>
                                    {getAddonPrice(addon, data.price) === 0 ? 
                                    <td className='right'>{ADDONS.GET_LABEL(addon)}</td> : 
                                    <td className='right'>
                                        ${getAddonPrice(addon, data.price)??'...'} {currency}
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                ]);
                break;
            }
    }, [data]);

    function handleCheckout() {
        if(price === (0).toFixed(2)){
            return;
        }
        if (data.champions && data.champions.length) {
            const jsonString = JSON.stringify(data.champions);
            if (jsonString.length > 500) {
                MySwal.fire({
                    title: 'Too many champions selected',
                    text: 'Please select less champions',
                    icon: 'error'
                });
                return;
            }
        }
        axios.post(process.env.REACT_APP_API_URL + `/${admin ? 'admin' : 'user'}/${admin ? 'checkout' : 'stripe-checkout'}`, {
            order_type: panel !== 'Coaching' ? 'boost' : 'coaching', 
            boost_type: panel, 
            server: data.server, 
            coupon: discount ? coupon : null, 
            division_boosting: {
                rank: data.rank,
                division: data.division,
                LP: data.LP,
                queue_type: data.queueType,
                desired_rank: data.desiredRank,
                desired_division: data.desiredDivision,
                LP_gains: data.LPGains,
                addons: data.addons,
                roles: data.roles,
                champions: data.champions,
            },
            placement_games: {
                previous_rank: data.rank,
                num_games: data.numGames,
                queue_type: data.queueType,
                server: data.server,
                addons: data.addons,
                roles: data.roles,
                champions: data.champions,
            },
            net_wins: {
                initial_rank: data.rank,
                initial_division: data.division,
                current_LP_gains: data.LPGains,
                num_net_wins: data.numNetWins,
                queue_type: data.queueType,
                addons: data.addons,
                roles: data.roles,
                champions: data.champions,
            },
            normal_games: {
                order_type: data.orderType,
                num_games: data.numGames,
                addons: data.addons,
                roles: data.roles,
                champions: data.champions,
            },
            coaching: {
                coach_rank: data.coachRank,
                users_rank: data.usersRank,
                language: data.orderLanguage,
                copilot_hours: data.copilotHours,
                vod_hours: data.vodHours,
                battle_hours: data.battleHours,
                server: data.server,
                roles: data.roles,
                champions: data.champions,
            },
            currency: currency
        }, {
            headers: {
                Authorization: getAccessToken()
            }
        }).then((response) => {
            window.location.href = response.data.url;
        }).catch((error) => {
            console.log(error);
        });
    }

    function couponHandler() {
        //set the coupon to caps

        if(getAccessToken()) {
            axios.get(process.env.REACT_APP_API_URL + `/${admin ? 'admin' : 'user'}/coupon-validator/${coupon.toUpperCase()}`, {
                headers: {
                    Authorization: getAccessToken()
                }
            }).then((response) => {
                setDiscountPercent(response.data);
                setDiscount(true);
            }).catch((error) => {
                setDiscountPercent(0);
                setDiscount(false);
                setCoupon('');
                setShakeCoupon(true);
                setTimeout(() => {
                    setShakeCoupon(false);
                }, 500);
            });
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/coupon-validator/' + coupon.toUpperCase()).then((response) => {
            setDiscountPercent(response.data);
            setDiscount(true);
        }).catch((error) => {
            setDiscountPercent(0);
            setDiscount(false);
            setCoupon('');
            setShakeCoupon(true);
            setTimeout(() => {
                setShakeCoupon(false);
            }, 500);
        });
    }

    useEffect(() => {
        if(!discount){
            setDiscountPercent(0);
            setCoupon('');
        }
        if(discount && price < 5 && currency === 'USD'){
            setShakeCoupon(true);
            setDiscountPercent(0);
            setCoupon(''); 
            setDiscount(false);
            MySwal.fire({
                title: 'Minimum Order Value',
                text: `The minimum order value is $5 ${currency}`,
                icon: 'error'
            });
            setTimeout(() => {
                setShakeCoupon(false);
            }, 500);
        } else if(discount && price < 100 && currency === 'MXN'){
            setShakeCoupon(true);
            setDiscountPercent(0);
            setCoupon(''); 
            setDiscount(false);
            MySwal.fire({
                title: 'Minimum Order Value',
                text: `The minimum order value is $100 ${currency}`,
                icon: 'error'
            });
            setTimeout(() => {
                setShakeCoupon(false);
            }, 500);
        }
    }, [discount]);

    useEffect(() => {
        if(discount && price !== 0 && price < 5 && currency === 'USD'){
            setShakeCoupon(true);
            setDiscountPercent(0);
            setCoupon(''); 
            setDiscount(false);
            MySwal.fire({
                title: 'Minimum Order Value',
                text: `The minimum order value is $5 ${currency}`,
                icon: 'error'
            });
            setTimeout(() => {
                setShakeCoupon(false);
            }, 500);
        } else if(discount && price !== 0 && price < 100 && currency === 'MXN'){
            setShakeCoupon(true);
            setDiscountPercent(0);
            setCoupon(''); 
            setDiscount(false);
            MySwal.fire({
                title: 'Minimum Order Value',
                text: `The minimum order value is $100 ${currency}`,
                icon: 'error'
            });
            setTimeout(() => {
                setShakeCoupon(false);
            }, 500);
        }
    }, [price]);

    return(
        <div className="order-now-checkout">
            <div ref={checkoutRef} id='order-now-checkout-component' style={{top:`calc(50% - (${checkoutHeight}px/2)`}}>
                <div className="component-title">
                    <h1>Your Order</h1>
                </div>
                <div className="component-wrapper">
                    <div className="order-now-checkout-content">
                        <table>
                            {content}
                        </table>
                    </div>
                    <div className="order-now-checkout-discount">
                        {discount ? 
                        <div className='order-now-checkout-discount-applied'>
                            <p>{coupon}</p>
                            <button  id='discount-code-btn' onClick={() => setDiscount(false)}>Remove</button>
                        </div> :
                        <> 
                            <div className={`order-now-checkout-apply-discount${shakeCoupon ? ' shake' : ''}`}>
                                <input id='discount-code-input' value={coupon} placeholder='Discount Coupon' onChange={(e) => setCoupon(e.target.value)} autoComplete='off'/>
                                <button  id='discount-code-btn' onClick={() => couponHandler()}>Apply</button>
                            </div>
                        </>
                        }
                    </div>
                    <div className="order-now-checkout-total">
                        <div className="order-now-checkout-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='left strong'>Price</td>
                                        <td className='right'>${price??'...'} {currency}</td>
                                    </tr>
                                    <tr>
                                        <td className='left strong'>Discount</td>
                                        <td className='right'>${price?(price*discountPercent/100).toFixed(2):'...'} {currency}</td>
                                    </tr>
                                    <tr>
                                        <td className='left strong'>Total Price</td>
                                        <td className='right'>${price ? (price - (price*discountPercent/100).toFixed(2)).toFixed(2) : '...'} {currency}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='order-now-checkout-btn'>
                        <button id='checkout-button' onClick={() => (getAccessToken()) ? handleCheckout() : setShowLogin()}>Checkout</button>
                        <img id='stripe-logo' src={require('../assets/Brands/stripe_white.png')}/>
                    </div>
                </div>
            </div>
        </div>
    );
}