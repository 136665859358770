
import React from 'react';

import './CSS/TOS.css';

export default function TermsOfService() {

    const [language, setLanguage] = React.useState('en');
    
    if(language === 'es') {
        return(
            <div className='terms-of-service'>
                <h1>Términos y Condiciones para Usuarios de SMURF GG</h1>
                <p>Última actualización: 24 de julio del 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                        1.0 PREÁMBULO
                    </h2>
                    <p>
                        Estos Términos y Condiciones establecen los rubros bajo los cuales se regula el uso de SMURF GG (en adelante, la “Página”).
                    </p>
                    <br />
                    <p>
                        SMURF GG administra y opera esta Página, mediante la cual se ofrecen al público en general servicios de Boosting y Coaching (según se define más adelante) para videojuegos multijugador como League of Legends (en adelante, “LOL”), Valorant, Apex Legends, TFT y más (colectivamente, los “videojuegos”).
                    </p>
                    <br />
                    <p>
                    SMURF GG no es la entidad responsable de proporcionar los servicios de Boosting y Coaching, sino que brinda los servicios en línea necesarios para habilitar a los Usuarios a conectar con los Entrenadores (según se define más adelante).
                    </p>
                    <br />
                    <p>
                        Los servicios de Boosting y Coaching ofrecidos a través de SMURF GG permiten el acceso a Usuarios ubicados en cualquier parte del mundo, siempre y cuando las leyes de cada determinado país lo permitan.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        2.0 DEFINICIONES
                    </h2>
                    <ul>
                        <li>
                            <p>
                                <strong>Usuario</strong>: Persona física que ingresa al sistema de SMURF GG y adquiere cualquiera de los Servicios ofrecidos en la Página.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Entrenador(es)</strong>: Personas relacionadas a SMURF GG que brindarán los Servicios de Boosting y Coaching a los Usuarios.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Boosting</strong>: Servicio que consiste en la intervención del Entrenador para elevar el nivel y/o rango de la cuenta de un Usuario de cualquier videojuego ofrecido en la plataforma a un nivel y/o rango determinado, según lo haya solicitado el Usuario.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coaching</strong>: Servicio que consiste en la orientación, preparación, enseñanza y asesoría de un Entrenador directamente con el Usuario con el objetivo de mejorar su desempeño y experiencia de juego en LOL o cualquiera de los videojuegos que ofrece la Página, según lo haya solicitado el Usuario.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Cuenta SMURF GG</strong>: Se refiere particularmente al registro que realizará el Usuario en la Página de SMURF GG y mediante la cual el Usuario podrá adquirir los servicios de Coaching y Boosting ofrecidos.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Summoner Name</strong>: Se refiere al nombre visible y perfil individual de LOL que utiliza cada uno de los Usuarios en LOL y/o demás videojuegos.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Username</strong>: Se refiere al nombre de usuario para acceder a la cuenta de LOL y/o cualquier otro Videojuego, el cual puede ser distinto al Summoner Name.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Servicios</strong>: Se refiere a uno o varios de los servicios ofrecidos en la Página, ya sea de Coaching, Boosting o cualquier otro.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Addons (servicios adicionales)</strong>: Se refiere a cualquiera de los siguientes servicios que estarán disponibles para el Servicio de Boosting:
                                <ul>
                                    <li>
                                        <p>
                                            i) <strong>Duo</strong>: El Usuario juega simultáneamente con el Entrenador.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ii) <strong>VPN</strong>: El Entrenador utiliza un VPN para asimilar la ubicación geográfica por IP del Usuario.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iii) <strong>Livestream</strong>: Vía Discord.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iv) <strong>Specific Roles</strong>: El Entrenador utiliza los roles que el Usuario le solicite. En ciertos casos, el Entrenador estará forzado a utilizar otro rol, debido a falta de jugadores en el Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            v) <strong>Bonus Win</strong>: Una vez alcanzado el objeto solicitado por el Usuario, el Entrenador juega y gana una partida adicional para asegurar que el Usuario no esté en riesgo de bajar de nivel.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vi) <strong>Specific Champions</strong>: El Entrenador utiliza los campeones que solicite el Usuario. Existen casos en los que no es posible utilizar a un determinado campeón por (i) el campeón ya fue elegido por algún jugador dentro de la partida, (ii) el campeón fue prohibido por los integrantes del equipo y/o los contrarios y (iii) el campeón está desactivado por fallas del Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vii) <strong>Appear Offline</strong>: El Entrenador utiliza aplicaciones como “Deceive”, con el objetivo de que no aparezca en línea dentro del Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            viii) <strong>Solo Play</strong>: El Entrenador juega las partidas que le corresponden solo, es decir, sin la intervención del Usuario o de otro Entrenador.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ix) <strong>Priority</strong>: Por un precio adicional, se amplía la búsqueda de Entrenadores y la posibilidad de se acepte la oferta.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        3.0 APLICACIÓN DE LOS TÉRMINOS Y CONDICIONES
                    </h2>
                    <p>
                        Los Usuarios que creen una Cuenta SMURF GG y/o adquieran cualquiera de los Servicios estarán legalmente sujetos a estos Términos y Condiciones. 
                    </p>
                    <br />
                    <p>
                        En caso de que las normas regionales y nacionales le prohíban al Usuario el uso de los Servicios ofrecidos en esta Página, el Usuario deberá abstenerse de adquirir los Servicios.
                    </p>
                    <br />
                    <p>
                        SMURF GG se reserva el derecho de realizar cambios y modificaciones a los Términos y Condiciones actuales, los cuales serán promulgados y notificados a los Usuarios por medio de la Página, por lo que es responsabilidad de los Usuarios conocer y revisar los Términos y Condiciones.
                    </p>
                    <br />
                    <p>
                        El Usuario acepta los Términos y Condiciones al adquirir cualquiera de los Servicios ofrecidos por SMURF GG y/o al crear su Cuenta SMURF GG.
                    </p>
                    <br />
                    <p>
                        La comunicación entre los Usuarios y SMURF GG se realizará principalmente por medio de la plataforma conocida como “Discord”, a través del servidor de soporte de SMURF GG. Los Usuarios podrán contactar a SMURF GG por medio del correo electrónico que brinde el Usuario al crear su Cuenta SMURF GG. Los Entrenadores podrán optar adicionalmente por entablar comunicación con el Usuario, para el desempeño de los Servicios, por medio de plataformas digitales como “Twitch” y/o “Discord”.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        4.0 CAPACIDAD 
                    </h2>
                    <p>
                        El Usuario que realice compras o adquiera los Servicios ofrecidos en esta Página declara que ha leído y comprendido los presentes Términos y Condiciones y acuerda sujetarse a ellos.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        5.0 INSCRIPCIÓN DEL USUARIO 
                    </h2>
                    <p>
                        Para acceder y pagar por los Servicios, el Usuario deberá crear una Cuenta SMURF GG. Para abrir la Cuenta SMURF GG, el Usuario deberá proporcionar la información solicitada de forma correcta y verídica. El Usuario se reserva el derecho de actualizar la información cuando sea necesario. La información solicitada incluye mas no se limita a lo siguiente:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Nombre y Apellido del Usuario
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Username de la Cuenta SMURF GG
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Correo Electrónico
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Contraseña para ingresar al videojuego (únicamente para Boosting)
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Summoner Name (LOL) y/o identificador para otro videojuego
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Fecha de Nacimiento (opcional, para motivos de estadística)
                            </p>
                        </li>
                    </ul>
                    <p>
                        El no proporcionar la información solicitada de forma correcta y verídica se considerará como incumplimiento a los presentes Términos y Condiciones y, por lo tanto, el Usuario podrá ser sujeto a suspensión de su Cuenta SMURF GG y/o cancelación inmediata de los Servicios adquiridos.
                    </p>
                    <br />
                    <p>
                        Al proporcionar la información solicitada, el Usuario otorga el derecho a SMURF GG de divulgar los datos necesarios (dependiendo del Servicio adquirido) exclusivamente a los Entrenadores que ofrezcan los Servicios de Coaching y Boosting. SMURF GG no compartirá ni divulgará los datos de ningún Usuario a terceros no autorizados. Para mayor información al respecto, consulte nuestro Aviso de Privacidad.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        6.0 PROPIEDAD INTELECTUAL
                    </h2>
                    <p>
                        SMURF GG no tiene afiliación alguna con Riot Games.
                    </p>
                    <br />
                    <p>
                        SMURF GG declara que no es propietario de Riot Games y, por lo tanto, los aspectos de propiedad intelectual de LOL son enteramente propiedad de Riot Games, quien cuenta con los derechos de autor correspondientes.
                    </p>
                    <br />
                    <p>
                        La marca SMURF GG está protegida bajo las normativas de Propiedad Intelectual de los Estados Unidos Mexicanos. El uso indebido o no autorizado de la marca SMURF GG será sancionado conforme a la ley. 
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        7.0 PAGOS EN LA PÁGINA WEB
                    </h2>
                    <p>
                        Todos los pagos por la adquisición de los Servicios ofrecidos en SMURF GG serán a través de la plataforma conocida como “Stripe”. SMURF GG podrá realizar cambios a la forma de pago para incluir la opción de una terminal de pago digital como PayPal, entre otros. SMURF GG nunca solicitará los datos bancarios de los Usuarios.
                    </p>
                    <br />
                    <p>
                        Nos reservamos el derecho de cancelar su pedido en cualquier momento por determinados motivos como lo es el error en el pedido, información errónea, o si el Servicio ha sido retirado. En tal supuesto, no se realizará el cobro al Usuario o, si resulta aplicable, se le aplicará un reembolso sobre el monto pagado.
                    </p>
                    <br />
                    <p>
                        Para acceder al pago de los servicios de Boosting regular, el Usuario deberá proporcionar al sistema con su nombre completo, Cuenta SMURF GG, Username, contraseña y el nombre con el que se le conoce en LOL (Summoner Name). No será requerida la contraseña del Usuario de su cuenta SMURF GG y/o Username de LOL o cualquier otro Videojuego para Servicios de Coaching o Boosting con la modalidad “Duo”.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        8.0 CANCELACIONES Y REEMBOLSOS
                    </h2>
                    <p>
                        El Usuario tendrá la opción de cancelar los Servicios que hubiere adquirido y, en ciertos casos, obtener un reembolso. A todos los reembolsos se les aplicará un descuento del 10% (diez por ciento) sobre el valor total pagado por el Usuario. La cancelación de los Servicios adquiridos deberá ser solicitada por el Usuario de la siguiente manera:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Boosting: Para solicitar la cancelación y reembolso del Servicio de Boosting, el Usuario deberá comenzar el proceso de cancelación antes de que le sea asignado un Entrenador. Una vez que se le haya asignado un Entrenador, se considerará que el Servicio de Boosting ha comenzado y, por lo tanto, el Usuario podrá cancelar el Servicios pero no tendrá derecho a percibir un reembolso.
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Coaching: Para solicitar la cancelación del Servicio de Coaching o, en su caso, reembolso por inconformidad con el Servicio brindado, el Usuario deberá solicitar la cancelación y solicitar el reembolso por medio un correo electrónico dirigido a support@smurf.gg, en el cual explique el caso. El departamento de soporte de SMURF GG evaluará la solicitud del reembolso e informará al Usuario si es procedente y, en su caso, si procede un reembolso parcial o total, dependiendo del momento en el que el Usuario haya solicitado el reembolso. En caso de determinarse procedente, el reembolso se realizará al Usuario en el término de 30 (treinta) días posteriores a la recepción de la solicitud.
                            </p>
                        </li>
                    </ul>
                    <p>
                        Cualquier queja y/o inconformidad deberá ser presentada por el Usuario al correo electrónico support@smurf.gg, en el cual deberá detallar la inconformidad y explicar el caso. El departamento de soporte de SMURF GG evaluará la queja y procederán a responder al Usuario. En caso de no recibir contestación inmediata, significa que la solicitud está en proceso de evaluación y resolución.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        9.0 JURISDICCIÓN Y COMPETENCIA
                    </h2>
                    <p>
                        La jurisdicción aplicable para cualquier especie de disputa, controversia, resolución jurisdiccional será exclusivamente la de los Estados Unidos Mexicanos (México). SMURF GG y sus empresas filiales son entidades de nacionalidad mexicana y, por consecuencia, están sujetas a las leyes nacionales relacionadas con la prestación de servicios, derechos de autor, resolución de controversias y obligaciones para proteger datos personales de los Usuarios.
                    </p>
                    <br />
                    <p>
                        Al aceptar estos términos y condiciones, el Usuario acepta su conformidad y renuncia a cualquier otra jurisdicción que le pueda corresponder por virtud de su domicilio presente o futuro y/o nacionalidad.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        10.0 NO RESPONSABILIDAD
                    </h2>
                    <p>
                        SMURF GG NO será considerado responsable por la clausura o suspensión de las Cuentas de cualquier videojuego que se ofrezca en la Página o cualquier otro tipo de penalización que pudiesen sufrir los Usuarios por la evasión o el incumplimiento de las políticas de Riot Games y/o cualquier otro desarrollador de videojuegos. 
                    </p>
                    <br />
                    <p>
                        SMURF GG no participa de forma alguna en la relación contractual que el Usuario tenga con Riot Games y/o cualquier otro desarrollador de videojuegos.
                    </p>
                    <br />
                    <p>
                        SMURF GG no se hace responsable si los correos electrónicos que envíe a los Usuarios son clasificados como spam o basura por el servidor de correo del Usuario. 
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        11.0 FECHA DE INICIO Y VIGENCIA DE LOS TÉRMINOS Y CONDICIONES
                    </h2>
                    <p>
                        Estos Términos y Condiciones serán aplicables para cualquier Usuario a partir de que realice una compra en la Página, y se perfeccionarán desde que se confirme la compra del Servicio deseado. 
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Idioma</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>Inglés</option>
                        <option value='es'>Español</option>
                    </select>
                </div>
            </div>
        );
    } else if(language === 'en') {
        return(
            <div className='terms-of-service'>
                <h1>Terms & Conditions for SMURF GG Users</h1>
                <p>Last updated: July 24, 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                        1.0 FOREWORD
                    </h2>
                    <p>
                    These terms and conditions lay out the guidelines by which the general use of SMURF GG (henceforth, the “Website”) is regulated.
                    </p>
                    <br />
                    <p>
                    SMURF GG manages and operates this Website, by which Boosting and Coaching services (as defined later on) for multiplayer videogames such as League of Legends (henceforth, “LOL”), Valorant, Apex Legends, TFT, and others (collectively referred to as the “Videogames”) are offered to the public.
                    </p>
                    <br />
                    <p>
                    SMURF GG is not the entity responsible for providing the Boosting and Coaching services, but rather offers the necessary online services to enable Users to connect with Coaches (as definer later on).
                    </p>
                    <br />
                    <p>
                    Boosting and Coaching Services offered through SMURF GG are offered to Users regardless of their geographical location, provided the laws of their respective country allow such services.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        2.0 DEFINITIONS
                    </h2>
                    <ul>
                        <li>
                            <p>
                                <strong>User</strong>: Any person that signs up to SMURF GG and purchases any of the Services offered on the Website.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coach</strong>: Any person related to SMURF GG that provides Boosting and/or Coaching Services to the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Boosting</strong>: Service that consists in the intervention of a Coach to increase the level and/or rank of a User’s account for any of the Videogames offered on the Website to a given level and/or rank, as requested by the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coaching</strong>: Service that consists in guidance, preparation, teaching, and/or counseling offered by a Coach directly to a User, in order to improve the User’s performance and in-game experience for LOL or any of the Videogames offered on the Website, as requested by the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>SMURF GG Account</strong>: Refers to the account created by the User when signing up to SMURF GG through the Website, in order to purchase any of the Services that are offered.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Summoner Name</strong>: Refers to the visible name and individual gaming profile that is used by the User for LOL or any of the Videogames.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Username</strong>: Refers to the username used to access an individual LOL account or an account for any of the Videogames, which may differ from Summoner Name.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Services</strong>: Refers to any of the services offered on the Website, be it Coaching, Boosting, or Addons.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Addons (additional services)</strong>: Refers to any of the following, available as complements to the Boosting Service:
                                <ul>
                                    <li>
                                        <p>
                                            i) <strong>Duo</strong>: User plays simultaneously with a Coach.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ii) <strong>VPN</strong>: Coach uses a VPN to match User’s geographical location by IP Address.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iii) <strong>Livestream</strong>: Via Discord.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iv) <strong>Specific Roles</strong>: Coach uses the roles during gameplay requested by User. In certain cases, the Coach will be required to use a different role, due to insufficient players in a match or Videogame.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            v) <strong>Bonus Win</strong>: Once the objective requested by User is fulfilled, Coach plays an additional match to ensure that User is out of risk of decreasing level.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vi) <strong>Specific Champions</strong>: Coach plays with the champion(s) requested by User. There are cases in which it will not be possible to use a specific champion, due to (i) the unavailability of the champion because it has already been chosen by another player in a match, (ii) the champion has been banned by the team members and/or opponents, and (iii) the champion has been deactivated due to Videogame malfunction.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vii) <strong>Appear Offline</strong>: Coach employs apps such as “Deceive” to avoid appearing online in the Videogame.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            viii) <strong>Solo Play</strong>: Coach plays the required matches completely solo, without the intervention of the User or another Coach.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ix) <strong>Priority</strong>: For an additional price, the search for a Coach is widened and with it the possibility of an offer being accepted.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        3.0 ENFORCEMENT OF TERMS AND CONDITIONS
                    </h2>
                    <p>
                    Users that set up a SMURF GG account and/or purchase any of the Services offered on the Website shall be bound to these Terms and Conditions.
                    </p>
                    <br />
                    <p>
                    In case that regional and/or national laws forbid a User to purchase and use the Services offered on the Website, the User must refrain from purchasing said Services.                    </p>
                    <br />
                    <p>
                    SMURF GG reserves the right to amend and modify these current Terms and Conditions, which shall then be published and notified to the Users via the Website. Hence, it is the Users’ responsibility to know and review the Terms and Conditions.
                    </p>
                    <br />
                    <p>
                    The User accepts these Terms and Conditions upon the purchase of any of the Services offered by SMURF GG and/or upon creation of the User’s SMURF GG Account.
                    </p>
                    <br />
                    <p>
                    Communication between Users and SMURF GG will take place primarily through “Discord”, through SMURF GG’s support server. Users may also contact SMURF GG through the email address provided by the User when setting up the SMURF GG Account. Coaches may additionally opt to establish communication with the User through digital platforms such as “Twitch” and/or “Discord”, for the provision of Services.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        4.0 CAPACITY 
                    </h2>
                    <p>
                    Users who purchase any of the Services offered on this Website declare that these Terms and Conditions have been read and understood and therefore agree to be bound by them.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        5.0 USER REGISTRATION AND SIGN UP
                    </h2>
                    <p>
                    In order to access and pay for the Services, User must have an active SMURF GG Account. To open a SMURF GG Account, User must provide the requested information truthfully and accurately. User will have the right to update the information when needed. The information that will be requested from User includes but is not limited to:                    </p>
                    <ul>
                        <li>
                            <p>
                                i) User’s Name and Last Name
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) SMURF GG Account’s Username
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Email Address
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Password to access Videogame (only for Boosting)
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Summoner Name (LOL) and/or identifying name for different Videogame
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Date of Birth (optional, for statistical purposes)
                            </p>
                        </li>
                    </ul>
                    <p>
                    Failure to submit the required information truthfully will be considered breach to the present Terms and Conditions and, therefore, the User’s SMURF GG Account may be subject to suspension and/or immediate cancellation of the Services that were purchased.
                    </p>
                    <br />
                    <p>
                    Submission of the required information shall be deemed as User’s consent for SMURF GG to disclose the essential data (depending on the Service that was purchased) exclusively to the Coaches that offer Coaching and Boosting Services. SMURF GG will never disclose or share the User’s information to any unauthorized third party. For more information, please refer to our Privacy Notice.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        6.0 INTELLECTUAL PROPERTY
                    </h2>
                    <p>
                    SMURF GG is not affiliated with Riot Games.
                    </p>
                    <br />
                    <p>
                    SMURF GG warrants that it does not own any interest in Riot Games and, therefore, any and all intellectual property and copyright aspects pertaining to LOL are wholly property of Riot Games.
                    </p>
                    <br />
                    <p>
                    SMURF GG’s brand is protected under the laws of Intellectual Property of the United Mexican States (Mexico). The misuse or unauthorized use of SMURF GG’s brand and image may be punishable by law. 
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        7.0 PAYMENTS ON THE WEBSITE
                    </h2>
                    <p>
                    All payments for the purchase of Services offered on SMURF GG shall be made through the platform known as “Stripe”. SMURF GG may carry out changes to the payment method to include the option of a digital payment system such as PayPal or others. SMURF GG will never request bank details from the Users.
                    </p>
                    <br />
                    <p>
                    We reserve the right to cancel an order at any given moment for reasons such as mistakes in the order, inaccurate information, or in case the Service has been withdrawn. In such cases, User will not be charged and, if the case may be, a refund will be applied on the amount paid.
                    </p>
                    <br />
                    <p>
                    To access the payment site for regular Boosting Services, User must submit name and last name, SMURF GG Account, Username, Password, and the name visible to other players in LOL (Summoner Name). User’s password for SMURF GG Account and/or LOL account shall not be required for Coaching Services and Boosting with the Duo Addon.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        8.0 CANCELLATIONS AND REFUNDS
                    </h2>
                    <p>
                    User will have the option to cancel the Services and, in some cases, obtain a refund. A 10% (ten per cent) discount on the total amount paid by the User is applied to all refunds. The cancellation of the purchased Services must be requested by the User as follows:                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Boosting: In order to request the cancellation and refund of Boosting Services, User must start the cancellation process before a Coach has been assigned to the order. Once a Coach has been assigned, the Boosting Service will be considered as initiated and effective. In such case, User may be able to cancel the Service but will not have the right to claim a refund.
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Coaching: In order to request the cancellation of Coaching Services or, if applicable, refund for dissatisfaction with the Service provided, User must request cancellation and request a refund by sending an email to support@smurf.gg, detailing the issue. SMURF GG’s support department will assess the User’s request and inform the User if the request is valid and, as the case may be, if a partial or total refund is applicable, which shall depend on the moment at which User requests the refund. In case the request is approved, refund shall be paid to User within 30 (thirty) days counting from receipt of the request. 
                            </p>
                        </li>
                    </ul>
                    <p>
                    Any and all complaints or nonconformities must be submitted by the User to the email support@smurf.gg, in which a detailed account of the issue must be provided. SMURF GG’s support department will assess the complaint or nonconformity and reply to the User within a reasonable timeframe. Failure to receive an immediate response means that the request is being assessed and resolved.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        9.0 JURISDICTION
                    </h2>
                    <p>
                    In case of controversy or dispute, the applicable jurisdiction shall be that of the competent courts of the United Mexican States (Mexico). SMURF GG and its affiliates are entities of Mexican nationality and, as such, are bound by Mexican Laws regarding the provision of services, copyright, dispute resolution, and obligations to protect the User’s personal information.
                    </p>
                    <br />
                    <p>
                    By accepting these Terms and Services, User accepts the aforementioned jurisdiction and waives any claim or right to any other jurisdiction that may correspond by virtue of User’s address, present or future and/or nationality.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        10.0 EXTENT OF RESPONSIBILITY
                    </h2>
                    <p>
                    SMURF GG shall bear no responsibility for the suspension or cancellation of the Users’ Accounts in any of the Videogames offered on the Website or for any type of penalty that may be imposed on the Users due to the evasion or breach of Riot Games’ or any other Videogame developer’s policies.
                    </p>
                    <br />
                    <p>
                    SMURF GG does not and has not entered into a contractual relationship with Riot Games and/or any Videogame developer company.
                    </p>
                    <br />
                    <p>
                    SMURF GG shall bear no responsibility in case the emails sent to the User’s registered email addresses are classified as spam or junk by the User’s email server.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        11.0 STARTING DATE AND EFFECT OF TERMS AND CONDITIONS
                    </h2>
                    <p>
                    These Terms and Conditions shall be applicable for all Users upon the completion of a purchase on the Website and shall enter into force from the moment said purchase is confirmed.
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Language</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>English</option>
                        <option value='es'>Spanish</option>
                    </select>
                </div>
            </div>
        );
    }
}