
import React from 'react'

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faUserGroup, faUserClock, faShieldHalved, faWifi, faLocationCrosshairs, faUserShield, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function handleAddon(addon) {
    switch(addon) {
        case 'Duo':
            return (<FontAwesomeIcon icon={faUserGroup} className='addon-icon'/>);
        case 'VPN':
            return (<FontAwesomeIcon icon={faShieldHalved} className='addon-icon'/>);
        case 'Priority':
            return (<FontAwesomeIcon icon={faUserClock} className='addon-icon'/>);
        case 'Streaming':
            return (<FontAwesomeIcon icon={faWifi} className='addon-icon'/>);
        case 'Specific Champions':
            return (<FontAwesomeIcon icon={faUsers} className='addon-icon'/>);
        case 'Specific Roles':
            return (<FontAwesomeIcon icon={faLocationCrosshairs} className='addon-icon'/>);
        case 'Solo Only':
            return (<FontAwesomeIcon icon={faUser} className='addon-icon'/>);
        case 'Appear Offline':
            return (<FontAwesomeIcon icon={faEyeSlash} className='addon-icon'/>);
        case 'Bonus Win':
            return (<FontAwesomeIcon icon={faUserShield} className='addon-icon'/>);
        default:
            return (<></>);
    }
}

export function Roles({elements}) {

    return(
        <div className='roles-container'>
            {elements.length === 0 ? 
                <img key={'Fill'} id='role' src={require(`../assets/Positions/Fill.png`)}/> 
                    : 
                elements.map((role) => {
                    return (<img key={role} id='role' src={require(`../assets/Positions/${role}.png`)}/>);
                })
            }
        </div>
    );
}

export function Addons({elements}) {



    return(
        <div className='addons-container'>
            {elements.map((addon) => {
                return (
                    <div key={addon} className='addon-details'>
                        {handleAddon(addon)}
                        <h3>{addon}</h3>
                    </div>
                );
            })}
            
            {elements.length === 0 && <h3>None</h3>}
        </div>
    );
}



export function Champions({elements}) {
    return(
        <div className='champions-container'>
            {elements.length === 0 ? 
                <h3>Any</h3>
                    : 
                elements.map((champion, index) => {
                    return (<p key={index}>{champion}</p>);
                })
            }
        </div>
    );
}


export function RoleSelector({ selectedRoles, noScroll, previousRoles }) {
    const roles = ['Top', 'Jungle', 'Mid', 'ADC', 'Support'];
    const [selected, setSelected] = React.useState(previousRoles || []);
    const roleSelectorRef = React.useRef(null);

    React.useEffect(() => {
        selectedRoles(selected);
    }, [selected]);

    function addRole(role) {
        if (selected.includes(role)) {
            setSelected(selected.filter((selectedRole) => selectedRole !== role));
        } else {
            setSelected([...selected, role]);
        }
    }

    React.useEffect(() => {
        // Scroll to the bottom of the .role-selector div when selected roles change
        if(noScroll) {
            return;
        }
        if (roleSelectorRef.current) {
            const container = roleSelectorRef.current;
            const containerRect = container.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const currentScrollPosition = window.scrollY || window.pageYOffset;
    
            // Calculate the scroll position to center the container vertically, considering current scroll position
            const scrollPosition = currentScrollPosition + containerRect.top + containerRect.height / 2 - viewportHeight / 2;
    
            // Scroll into view and center vertically
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [roleSelectorRef]);

    return (
        <div className='role-selector-container'>
            <div className='component-title'>
                <h2>Roles</h2>
            </div>
            <div className='component-wrapper'>
                <div className='role-selector' ref={roleSelectorRef}>
                    {roles.map((role) => {
                        return (
                            <div className='container' key={role}>
                                <div className={`role-effect ${selected.includes(role) ? 'selected' : ''}`} />
                                <img
                                    id='role'
                                    className={`${selected.includes(role) ? 'selected' : ''}`}
                                    onClick={() => addRole(role)}
                                    src={require(`../assets/Positions/${role}.png`)}
                                    alt={role}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}


export function ChampionSelector({ selectedChampions, noScroll, previousChampions }) {
    
    const champions = require('../constants/champions.json');

    const [selected, setSelected] = React.useState(previousChampions || []);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filteredChampions, setFilteredChampions] = React.useState(require('../constants/champions.json'));
    const champSelectorRef = React.useRef(null);
    

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        // Filter the champions based on the search term
        const filtered = Object.values(champions).filter((champion) =>
            champion.toLowerCase().includes(term)
        );
        setFilteredChampions(filtered);
    };

    React.useEffect(() => {
        selectedChampions(selected);
    }, [selected]);

    function addChampion(champion) {
        if(selected.includes(champion)) {
            setSelected(selected.filter((selectedChampion) => selectedChampion !== champion));
        } else {
            setSelected([...selected, champion]);
        }
    }

    React.useEffect(() => {
        // Scroll to the bottom of the .role-selector div when selected roles change
        if(noScroll) {
            return;
        }
        
        if (champSelectorRef.current) {
            const container = champSelectorRef.current;
            const containerRect = container.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const currentScrollPosition = window.scrollY || window.pageYOffset;
    
            // Calculate the scroll position to center the container vertically, considering current scroll position
            const scrollPosition = currentScrollPosition + containerRect.top + containerRect.height / 2 - viewportHeight / 2;
    
            // Scroll into view and center vertically
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [champSelectorRef]);

    return(
        <div className='champions-selector-container' ref={champSelectorRef}>
            <div className='component-title'>
                <h2>Champions</h2>
            </div>
            <div className='component-wrapper'>
                <div className='champions-search'>
                    <input 
                        type='text' 
                        placeholder='Search for a champion'
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <button>Search</button>
                </div>
                <div className='champion-selector-wrapper'>
                    <div className='champion-selector'>
                        {Object.values(filteredChampions).map((champion) => {
                            return <p key={champion} className={`${selected.includes(champion) ? 'selected' : ''}`} onClick={() => addChampion(champion)}>{champion}</p>
                        })}
                        {Object.values(filteredChampions).length === 0 && <h3>No champions found</h3>}
                    </div>
                </div>
            </div>
            <div className='component-title'>
            <h2>Selected Champions</h2>
            </div>
            <div className='component-wrapper'>
            <div className={`champion-selector-wrapper selected`}>
                <div className='champion-selector'>
                    {selected.length === 0 && <h3>None</h3>}
                    {selected.map((champion) => {
                        return <p key={champion} onClick={() => addChampion(champion)}>{champion}</p>
                    })}
                </div>
            </div>
            </div>
        </div>  
    );
}