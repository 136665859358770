


import React from 'react'

import logo from '../assets/Logos/Text.png';
import icon from '../assets/Logos/MascotFace.png';

import Popup from 'reactjs-popup';
import SignIn from './NavBar/SignIn.js';
import ProfileModal from './NavBar/ProfileModal.js';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';

import axios from 'axios';
import './NavBar/NavBar.css';

export default function NavBar() {

  const [isOpen, setIsOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = React.useState(false);

  const navigate = useNavigate();

  const menuRef = React.useRef();

  const { getUsername, logout, isBooster, isAdmin } = useAuth();

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const handleScroll = () => {
    const navbar = document.getElementById('navbar');
    if (window.scrollY > 50) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  }

  const handleFAQScroll = () => {
    if(!document.getElementById('FAQ')) {
      navigate('/');
      setTimeout(() => {
        handleFAQScroll();
      }, 100);
      return;
    }
    const navbarHeight = document.getElementById('navbar').offsetHeight;
    const faqElement = document.getElementById('FAQ');

    if (faqElement) {
      const offset = faqElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };
  
  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false);
      const menu = document.getElementById('menu-burger-media');
      menu.classList.remove('active');
    }
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    const menu = document.getElementById('menu-burger-media');
    isOpen ? menu.classList.remove('active') : menu.classList.add('active');
  }

  const handleLogoClick = () => {
    if (window.location.pathname === '/') {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    } else {
      navigate('/');
    }
  }

  let generalButtons = 
    (<>
      <li>
        <button className="nav-button" onClick={() => navigate('/get-boosting/division-boosting')}>Boosting</button>
      </li>
      <li>
        <button className="nav-button" onClick={() => navigate('/get-coaching/mixed')}>Coaching</button>
      </li>
      <li>
        <button className="nav-button" onClick={handleFAQScroll}>FAQ</button>
      </li>
      <li>
        <button className="nav-button" onClick={() => {
          axios.get(`${process.env.REACT_APP_DISCORD}`).then(response => {
            const discord_invite = response.data.instant_invite;
            window.open(discord_invite);
          }
        )}}>Discord</button>
      </li>
    </>);

  return(
    <nav id='navbar'>
      <div id='navbar-logo-container'>
        <img id='navbar-logo' src={logo} onClick={handleLogoClick} alt="Logo" />
      </div> 
      <div className='menu-burger' ref={menuRef} onClick={() => toggleMenu()}>
        <div id='menu'>
          {getUsername() &&
            <ul className="nav-links">
              {generalButtons}
              {getUsername() && <img id={`nav-icon${isProfileModalOpen?'-selected':''}`} src={icon} onClick={() => setIsProfileModalOpen(true)}/>}
              <Popup id='profile-modal-popup' open={isProfileModalOpen} position='top' closeOnEscape closeOnDocumentClick onClose={() => setIsProfileModalOpen(false)} modal nested>
                  <ProfileModal closeModal={() => setIsProfileModalOpen(false)} />
              </Popup>
            </ul>
          }
          {!getUsername() &&
            <ul className="nav-links">
              {generalButtons}
              <li>
                <button id='sign-in-button' onClick={() => setLoginOpen(true)}>Sign In</button>
              </li>
            </ul>
          }
        </div>
        <div id='menu-burger-media'>
          {<ul className="nav-links">
            {!getUsername() && <>
              <li>
                <button className="nav-button" onClick={() => setLoginOpen(true)}>Sign In</button>
              </li>
            </>}
            {getUsername() && <>
              <li>
                <button className="nav-button" onClick={() => navigate('/my-orders/boosting/active')}>My Orders</button>
              </li>
            </>}
            {isBooster && 
              <li>
                <button className="nav-button" onClick={() => navigate('/boost-panel/boosting/pending')}>Booster Dashboard</button>
              </li>
            }
            {isAdmin && 
              <li>
                <button className="nav-button" onClick={() => navigate('/admin-panel/boosting/pending')}>Admin Dashboard</button>
              </li>
            }
            {generalButtons}
            {getUsername() && <>
              <li>
                <button className="nav-button" onClick={() => logout()}>Log Out</button>
              </li>
            </>}
          </ul>}
        </div>
        <svg className="burger" viewBox="-1 -1 4 4" onClick={toggleMenu}>
          <path d="M0 0 H3 M0 1 H3 M0 2 H3" stroke="#ffffff" strokeWidth="0.3" strokeLinecap="round"/>
        </svg>
      </div>
      <SignIn open={loginOpen} close={() => setLoginOpen(false)}/>
    </nav>
  );
}